import instantsearch from "instantsearch.js";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { history } from "instantsearch.js/es/lib/routers";
import { default as searchBox } from "instantsearch.js/es/widgets/search-box/search-box";
import { rangeInput } from 'instantsearch.js/es/widgets';
import { default as hits } from "instantsearch.js/es/widgets/hits/hits";
import { default as panel } from "instantsearch.js/es/widgets/panel/panel";
import { default as rangeSlider } from "instantsearch.js/es/widgets/range-slider/range-slider";
import { default as refinementList } from "instantsearch.js/es/widgets/refinement-list/refinement-list";
import { clearRefinements } from 'instantsearch.js/es/widgets';
import { default as menuSelect } from "instantsearch.js/es/widgets/menu-select/menu-select";
import { default as sortBy } from "instantsearch.js/es/widgets/sort-by/sort-by";
import { default as stats } from "instantsearch.js/es/widgets/stats/stats";
// can't do this more convenient import because there is a bug in the bulk import about googlemaps
// import { hits, panel, rangeSlider, refinementList, menuSelect, menu, sortBy, stats } from 'instantsearch.js/es/widgets'
import { connectInfiniteHits } from "instantsearch.js/es/connectors";
import { RendererOptions } from "instantsearch.js/es/types/connector";
import type { BaseHit, HitAttributeHighlightResult } from "instantsearch.js/es/types/results";
import type { InfiniteHitsRenderState, InfiniteHitsConnectorParams } from "instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits";
import {
  countryNames,
  windowTitle,
  uiStateToRoute,
  routeToState,
  createURL,
  parseURL,
  parseCountryCode,
  baseSearchLink,
  stripHighlighting,
  imdbLink,
  capitalizeWord
} from "./lib";
import {
  IWHit
} from "./meili_types";


const featuredPlatforms: Array<string> = [
    'netflix',
    'prime',
    'hbo',
    'hulu',
    'showtime',
    'starz',
    'apple',
    'disney',
    'britbox',
    'iplayer',
    'mubi',
    'paramount',
    'peacock',
];

const platformNames: Record<string, string> = {
    all4: 'All4',
    apple: 'Apple',
    britbox: 'BritBox',
    curiosity: 'Curiosity',
    disney: 'Disney',
    hbo: 'HBO',
    hotstar: 'Hotstar',
    hulu: 'Hulu',
    iplayer: 'BBC iPlayer',
    mubi: 'MUBI',
    netflix: 'Netflix',
    now: 'NOW',
    paramount: 'Paramount',
    peacock: 'Peacock',
    prime: 'Amazon Prime',
    showtime: 'Showtime',
    starz: 'Starz',
    zee5: 'ZEE5',
};


const countryIdx = parseCountryCode(location.pathname);
let searchClient;

// Widgets showcase
// https://www.algolia.com/doc/guides/building-search-ui/widgets/showcase/js/

let meilisearchURL;

if (location.port == "") {
  // in production, we proxy through nginx
  meilisearchURL = `${location.protocol}//${location.host}`;  
} else {
  // in development, we hit local meilisearch instance directly
  meilisearchURL = `${location.protocol}//localhost:7700`;  
}

try {
  searchClient = instantMeiliSearch(
    meilisearchURL, "key"
  );
} catch (e) {
  const debugMsg = document.querySelector("#debug-message");
  if (debugMsg) {
    debugMsg.setAttribute("style", "display:block");
  }
  throw e;
}

const search = instantsearch({
  indexName: countryIdx,
  searchClient,
  routing: {
    /* BrowserHistoryArgs<TRouteState> */
    router: history({
      windowTitle,
      createURL,
      parseURL,
    }),
    stateMapping: {
      stateToRoute: uiStateToRoute(countryIdx),
      routeToState: routeToState(countryIdx),
    },
  },
});

const contentTypePanel = refinementList({
  container: "#content-types-list",
  attribute: "content_type",
  operator: "or",
  templates: {
    item: function (item, { html }) {
      return html`<label class="ais-RefinementList-label">
                      <input type="checkbox" class="ais-RefinementList-checkbox" value="${item.value}"/>
                      <span class="ais-RefinementList-labelText">${capitalizeWord(item.label)}</span>
                      <span class="ais-RefinementList-count">${item.count.toLocaleString()}</span></label>`;
    },
  },
});

const servicePanel = refinementList({
  container: "#services-list",
  attribute: "streaming_services",
  templates: {
    item: function (item, { html }) {
      return html`<label class="ais-RefinementList-label">
                      <input type="checkbox" class="ais-RefinementList-checkbox" value="${item.value}"/>
                      <span class="ais-RefinementList-labelText">${platformNames[item.value]}</span>
                      <span class="ais-RefinementList-count">${item.count.toLocaleString()}</span></label>`;
    },
  },
});

const genrePanel = refinementList({
  container: "#refinement-list",
  attribute: "genre_names",
//   templates: {
//     item: function (item, { html }) {
//       return html`<span>${item.label} (${item.count.toLocaleString()})</span>`;
//     },
//   },
});

const yearRange = rangeInput({
  container: "#year-range",
  attribute: "year",
  min: 1900,
  max: 2023,
//   step: 10,
//   pips: true,
});


const imdbRatingRange = rangeInput({
  container: '#imdb-rating-range',
  attribute: 'imdb_rating',
  min: 0.0,
  max: 10.0,
  precision: 1,
});


const sortByNewestOnPlatforms = featuredPlatforms.map((slug) => {
   return { label: `Newest on ${platformNames[slug]}`, value: `${countryIdx}:added_on_${slug}:desc` }
});

const sortSelect = sortBy({
  container: "#sort-by",
  items: [
    {
      label: "Most recent first",
      value: `${parseCountryCode(location.pathname)}:year:desc`,
    },
    {
      label: "IMDB rating - highest",
      value: `${parseCountryCode(location.pathname)}:imdb_rating:desc`,
    },
    {
      label: "IMDB rating - lowest",
      value: `${parseCountryCode(location.pathname)}:imdb_rating:asc`,
    },
    {
      label: "IMDB votes - most",
      value: `${parseCountryCode(location.pathname)}:imdb_vote_count:desc`,
    },
    {
      label: "IMDB votes - least",
      value: `${parseCountryCode(location.pathname)}:imdb_vote_count:asc`,
    },
    {
      label: "Oldest first",
      value: `${parseCountryCode(location.pathname)}:year:asc`,
    },
    {
      label: "Alphabetical order",
      value: `${parseCountryCode(location.pathname)}:alpha_title:asc`,
    },
  ].concat(sortByNewestOnPlatforms)
});

const countryCode = parseCountryCode(location.pathname);

function renderHit(hit: IWHit) {
  let poster;
  if (hit.image_sm) {
    poster = `<img src="${hit.image_sm}" class="iw-boxart" loading="lazy" />`;
  } else {
    // TODO: css rule to make box
    poster = `<div class="iw-boxart empty-poster">No Image</div>`;
  }
  let title_link = `/iw/${countryCode}/${hit.id}`;
  // https://www.algolia.com/doc/guides/building-search-ui/going-further/backend-search/in-depth/understanding-the-api-response/
  return `
      <article class="list-title iw-title box-synopsis-mode">
        <a class="title-link" href="${title_link}">${poster}</a>
        <span class="title">
          <a class="title-link hit-name" href="${title_link}">${
              (hit._highlightResult["title"] as HitAttributeHighlightResult).value
            }</a>
        </span>
        <div class="title-subheader">
          <span class="year"><a href="${baseSearchLink(location, { year: `${hit.year}:${hit.year}` })}">${hit.year}</a></span>
          <span class="average-rating">
            <a href="${imdbLink(hit.imdb_id)}"><i class='imdb-logo-small'></i></a> ${hit.imdb_rating ? hit.imdb_rating.toFixed(1) : "-"}
            (${hit.imdb_vote_count} votes)
          </span>
          <span class="content-type">${capitalizeWord(hit.content_type)}</span>
          <span class="genres">
            ${(hit._highlightResult.genre_names as HitAttributeHighlightResult[])
              .map(
                (x: { value: string }, _index: any) => {
                  const path = baseSearchLink(location, {
                    genres: [stripHighlighting(x.value)],
                  });
                  return `<a href="${path}">${x.value}</a>`;
                }
              )
              .join(", ")}
          </span>
        </div>
        <div class="synopsis hit-description">
          ${
            (hit._highlightResult.overview as HitAttributeHighlightResult).value
          } 
          <span class="cast">
            ${(hit._highlightResult.actors as HitAttributeHighlightResult[])
              .map(
                // add search link href
                (x: { value: string }, _index: any) => {
                  const path = baseSearchLink(location, {
                    q: `"${stripHighlighting(x.value)}"`,
                  });
                  return `<a href="${path}">${x.value}</a>`;
                }
              )
              .join(", ")}
          </span>
          <span class="availability">
            ${hit.streaming_service_infos
              .map((info, _index) => `<span>Added on <a href="${info.link}">${platformNames[info.service_name]}</a> ${info.added}${info.deleted ? `, leaving ${info.deleted}` : ""}.</span>
              `)
              .join(" ")}
          </span>
        </div>
      </article>
    `;
}

function renderEmpty() {
  return `<div>
          <p>No results have been found for {{ query }}</p>
          <a role="button" href="/iw/${countryCode}/search">Clear all filters</a>
        </div>`;
}


//let lastRenderArgs: any;
let lastRenderArgs: InfiniteHitsRenderState<BaseHit> & RendererOptions<InfiniteHitsConnectorParams<BaseHit> & object>;


// https://www.algolia.com/doc/api-reference/widgets/infinite-hits/js/#connector

const renderInfiniteHits = (renderArgs: any, isFirstRender: boolean) => {
    // node_modules/instantsearch.js/es/connectors/infinite-hits/connectInfiniteHits.js:207
    const { hits, showMore, widgetParams } = renderArgs;
    lastRenderArgs = renderArgs;
    if (isFirstRender) {
      const sentinel = document.createElement("ul");
      let paginationEl = document.querySelector("#pagination");
      if (paginationEl != null) {
        paginationEl.appendChild(sentinel);
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (entry.isIntersecting && !lastRenderArgs.isLastPage) {
                console.log("INFINITE PAGER");
                showMore();
              }
            }
          });
        });
        observer.observe(sentinel);
      }
      return;
    }
    const hitsContainer = document.querySelector("#hits");
    //const hitsContainer = widgetParams.container;
    if (hitsContainer) {
      hitsContainer.innerHTML = hits.map(renderHit).join("\n");
    }
  };

const customInfiniteHits = connectInfiniteHits(renderInfiniteHits);


const infiniteHitsContainer = customInfiniteHits({
                                  // tried false to translate HTML entities, but it's look messy
                                  escapeHTML: true
                                });

const statsWidget = stats({
  container: "#stats",
});

// https://www.algolia.com/doc/api-reference/widgets/clear-refinements/js/#options
const clearAllRefinementBtn = clearRefinements({
  container: '#clear-refinements',
  templates: {
    resetLabel({ hasRefinements }, { html }) {
      return html`<span>${hasRefinements ? 'Clear all filters' : 'No filters'}</span>`;
    },
  },
});

const clearPlatformRefinementBtn = clearRefinements({
  container: '#clear-platform-refinements',
  includedAttributes: ['streaming_services'],
  templates: {
    resetLabel({ hasRefinements }, { html }) {
      return html`<span>${hasRefinements ? 'Clear platforms' : 'No selections'}</span>`;
    },
  },
});
const clearGenreRefinementBtn = clearRefinements({
  container: '#clear-genre-refinements',
  includedAttributes: ['genre_names'],
  templates: {
    resetLabel({ hasRefinements }, { html }) {
      return html`<span>${hasRefinements ? 'Clear genres' : 'No selections'}</span>`;
    },
  },

});

search.addWidgets([
  searchBox({
    container: "#searchbox",
  }),
  statsWidget,
  contentTypePanel,
  servicePanel,
  genrePanel,
  yearRange,  // NOTE seems all non-movie titles have no year
  imdbRatingRange, 
  sortSelect,
  clearAllRefinementBtn,
  clearGenreRefinementBtn,
  clearPlatformRefinementBtn,
  infiniteHitsContainer,
]);

search.start();

// declare type MenuSelectWidget = WidgetFactory<MenuWidgetDescription & {
//     $$widgetType: 'ais.menuSelect';
// }, MenuConnectorParams, MenuSelectWidgetParams>;

// declare type MenuSelectTemplates = Partial<{
//     /**
//      * Item template, provided with `label`, `count`, `isRefined` and `value` data properties.
//      */
//     item: Template<{
//         label: string;
//         value: string;
//         count: number;
//         isRefined: boolean;
//     }>;
//     /**

// hitsContainerIgnored,
// Uncaught Error: The widget definition expects a `render` and/or an `init` method.
// https://www.algolia.com/doc/guides/building-search-ui/widgets/create-your-own-widgets/js/#connector

//      * Label of the "see all" option in the select.
//      */
//     defaultOption: Template;
// }>;



