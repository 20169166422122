import { default as qs } from "qs";
import type { Hit } from "instantsearch.js/es/types/results";
import type { IndexUiState } from "instantsearch.js/es/types/ui-state";

export type Route = {
  q: string | null;
  genres: Array<string>;
  country: string;
  year: string | null;  /* range like "2010:2020" */
  imdb_rating: string | null;  /* range */
  content_types: Array<string>;
  streaming_services: Array<string>;
  page: number;
  sort_by: string | null;
};

export type UIState = {
  [x: string]: {
    query: string;
    refinementList: {
      genre_names: string[];
      content_type: string[];
      streaming_services: string[];
    };
    range: { 
        year: string; 
        imdb_rating: string; 
    };
    page: number;
    sortBy: string;
  };
};


type CountryToIndexUiState = {
  [key: string]: IndexUiState;
};

// artificial type, adapted from instantsearch.js
type CreateURLParams = {
  qsModule: typeof qs;
  routeState: Route;
  location: Location;
};

/* 
  InstantSearch manages a state called uiState. It contains information
  like query, facets, or the current page, including the hierarchy of
  the added widgets. 

  To persist this state in the URL, InstantSearch first converts the
  uiState into an object called routeState. This routeState then becomes
  a URL. Conversely, when InstantSearch reads the URL and applies it to
  the search, it converts routeState into uiState. This logic lives in
  two functions:

    * uiStateToRoute: converts uiState to routeState.
    * routeToState: converts routeState to uiState.
*/

export function uiStateToRoute(
  countryCode: string
): (a: IndexUiState) => Route {
  return function (uiState: CountryToIndexUiState) {
    const indexUiState: IndexUiState = uiState[countryCode];
    // console.warn("indexUiState: ", JSON.stringify(indexUiState));
    let ret = {
      q: indexUiState.query,
      genres: indexUiState.refinementList && indexUiState.refinementList.genre_names,
      year: indexUiState.range && indexUiState.range.year,
      imdb_rating: indexUiState.range && indexUiState.range.imdb_rating,
      country: countryCode,
      content_types: indexUiState.refinementList && indexUiState.refinementList.content_type,
      streaming_services: indexUiState.refinementList && indexUiState.refinementList.streaming_services,
      sort_by: indexUiState.sortBy && indexUiState.sortBy,
      page: indexUiState.page,
    };
    return ret;
  };
}

// routeToState(routeState: TRouteState): TUiState;

export function routeToState(countryCode: string): (a: Route) => UIState {
  return function (routeState: Route) {
    let ret: UIState = {
      [countryCode]: {
        query: routeState.q,
        refinementList: {
          // must match the backend
          genre_names: routeState.genres,
          content_type: routeState.content_types,
          streaming_services: routeState.streaming_services,
        },
        range: { year: routeState.year,
                 imdb_rating: routeState.imdb_rating,
               },
        page: Number(routeState.page),
        sortBy: routeState.sort_by,
      },
    };
    return ret;
  };
}

// CreateURL_2<TRouteState>

export function createURL(args: CreateURLParams): string {
  let { qsModule: qs__, routeState, location } = args;
  const countryPath = routeState.country ? `${routeState.country}` : "us";
  const queryParameters: any = {};
  if (routeState.q) {
    queryParameters.q = routeState.q;
  }
//   if (routeState.page !== 1) {
//     queryParameters.page = routeState.page;
//   }
  if (routeState.sort_by) {
    queryParameters.sort_by = routeState.sort_by;
  }
  if (routeState.year) {
    queryParameters.year = routeState.year;
  }
  if (routeState.imdb_rating) {
    queryParameters.imdb_rating = routeState.imdb_rating;
  }
  if (routeState.genres&&routeState.genres.length) {
    queryParameters.genres = encodeURIComponent(routeState.genres.join(","));
  }
  if (routeState.content_types&&routeState.content_types.length) {
    queryParameters.content_types = encodeURIComponent(routeState.content_types.join(","));
  }
  if (routeState.streaming_services&&routeState.streaming_services.length) {
    queryParameters.streaming_services = encodeURIComponent(routeState.streaming_services.join(","));
  }
  const queryString = qs__.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  });
  let ret = `/iw/${countryPath}/search${queryString}`;
  return ret;
}

/*
declare type ParseURL<TRouteState> = (args: {
    qsModule: typeof qs_2;
    location: Location;
}) => TRouteState;
*/

export function parseURL(args: {
  qsModule: typeof qs;
  location: Location;
}): Route {
  const { qsModule, location } = args;
  const country = parseCountryCode(location.pathname);
  let parsed = qsModule.parse(location.search, { ignoreQueryPrefix: true });
  const q = typeof parsed.q == "string" ? parsed.q : "";
  // don't parse page in URL; we're doing infinite search
  //const page = typeof parsed.page != "string" ? 1 : Number(parsed.page);
  const year = typeof parsed.year != "string" ? null : parsed.year;
  const imdb_rating = typeof parsed.imdb_rating != "string" ? null : parsed.imdb_rating;
  const sort_by = typeof parsed.sort_by != "string" ? null : parsed.sort_by;
  let ret = {
    q: decodeURIComponent(q),
    page: 1,
    genres: splitString(parsed.genres),
    content_types: splitString(parsed.content_types),
    streaming_services: splitString(parsed.streaming_services),
    country,
    year,
    imdb_rating,
    sort_by,
  };
  return ret;
}

function splitString(value: any): string[] {
  if (Array.isArray(value)) {
    return (value as any[]).filter((x) => String(x) === x).map(decodeURIComponent);
  } else if (typeof value == "string") {
    return decodeURIComponent(value).split(",");
  } else {
    // null
    return [];
  }
}


// injects the qsModule
export function routeFromLocation(location: Location): Route {
  return parseURL({ qsModule: qs, location });
}


// Returns link e.g. for actor, genres, or whatever else
export function baseSearchLink(location: Location, newParams: object): string {
  let currentRoute = parseURL({ qsModule: qs, location });
  const resetRoute: Route = {
    ...currentRoute,
    page: 1,
    genres: [],
    year: null,
    imdb_rating: null,
    q: null,
  };
  const newRoute: Route = { ...resetRoute, ...newParams };
  return createURL({ qsModule: qs, routeState: newRoute, location: location });
}

export function parseCountryCode(pathname: string): string {
  const pathnameMatches = pathname.match(/iw\/([^/]*?)\/search/);
  const country = (pathnameMatches && pathnameMatches[1]) || "";
  return country;
}

export function stripHighlighting(s?: string): string {
  return s ? s.replace(/<\/?[^>]*>/g, "") : "";
}


export const countryNames: { [country_code: string]: string } = {
  us: "United States",
  gb: "Great Britain",
  kr: "South Korea",
};


export function windowTitle(routeState: Route): string {
  const { country, q, genres, streaming_services, content_types, year } = routeState;
  let filters = "";
  if (genres && genres.length) {
    filters += genres.join(" ")
  }
  if (content_types && content_types.length) {
    filters += " " + content_types.join(" ")
  }
  if (year) {
    filters += " " + year;
  }
  if (streaming_services && streaming_services.length) {
    filters += " on " + streaming_services.join(" ");
  }
  let queryTitle = q ? `Search for ${q} ${filters}` : `Search ${filters}`;
  if (country) {
    return `${queryTitle} ${country.toUpperCase()} | Instantwatcher`;
  }
  return queryTitle;
}


export function imdbLink(imdb_id: string): string {
  return `https://www.imdb.com/title/${imdb_id}/`
}

export function capitalizeWord(word:string): string {
  return (word.charAt(0).toUpperCase() + word.slice(1));
}
